import {
  Box as BoxComponent,
  Button as ButtonComponent,
  Typography as DSTypography,
  Icon as IconComponent
} from 'presentation/components/'
import styled from 'styled-components'

export const Box = styled(BoxComponent)``

export const Button = styled(ButtonComponent)`
  padding: 16px 20px;
  width: 284px;
  height: 56px;
`

export const Typography = styled(DSTypography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const Icon = styled(IconComponent)``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px 18px;
  gap: 8px;
  position: absolute;
  top: calc(50% - 366px / 2);
  background: #ffffff;
  box-shadow: 0px 16px 20px rgba(46, 45, 55, 0.12);
  border-radius: 8px;
  outline: none;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .close_modal_login {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: -8px;
    right: -2px;
    font-size: 20px;
    width: 24px;
    height: 24px;
  }

  h4 {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    text-align: center;
    line-height: 150%;
    max-width: 278px;
  }

  p {
    color: ${({ theme }) => theme.palette.grey[800]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    text-align: center;
    font-weight: 400;
    line-height: 120%;
  }
`
