import styled from 'styled-components'
import * as S from '../ToAppMobileModal.styles'

export const Box = styled(S.Box)``

export const Button = styled(S.Button)``

export const Typography = styled(S.Typography)``

export const Icon = styled(S.Icon)``

export const Container = styled(S.Container)`
  left: calc(50% - 334px / 2);
  width: 334px;
  height: 375px;
`

export const Content = styled(S.Content)`
  h4 {
    color: ${({ theme }) => theme.palette.grey[900]};
    font-size: 20px;
    font-weight: 500;
    margin-top: 18px;
    margin-bottom: 12px;
  }

  p {
    max-width: 294px;
    font-size: 8px;
    margin-bottom: 24px;
  }
`
