import blackFairImg from 'presentation/assets/img/abaixo-fipe-black.webp'
import * as S from './BlackFairContent.styles'

const BLACK_FAIR_START_DATE = '09/11'
const BLACK_FAIR_END_DATE = '30/11/2023'

export function BlackFairContent({ appUrl, handleClose, handleCTAClick }) {
  return (
    <S.Container className="modal_black_fair">
      <S.Content>
        <S.Box className="close_modal_login" onClick={handleClose}>
          <S.Icon icon="SLIM_CLOSE" />
        </S.Box>
        <img
          src={blackFairImg}
          alt="Ofertas relâmpago abaixo da FIPE - Black Feirão"
          width={230}
          height={104}
        />
        <S.Typography variant="h4">
          Aproveite ofertas de veículos abaixo da Fipe! Baixe já o App ;)
        </S.Typography>
        <S.Typography variant="body2">
          Oferta válida de {BLACK_FAIR_START_DATE} até {BLACK_FAIR_END_DATE}.
          *Crédito sujeito à análise e condições vigentes no momento da
          contratação. Consulte condições em
          webmotors.com.br/ofertas/feiroes/blackfeirao.
        </S.Typography>
        <S.Button
          href={appUrl}
          formTarget="_blank"
          id="btn_to_app"
          variant="secondary"
          onClick={handleCTAClick}
        >
          Quero aproveitar!
        </S.Button>
      </S.Content>
    </S.Container>
  )
}
