import * as S from './CategoryCard.styles'

import { Config } from 'application'

export function CategoryCard({ title, urlImage, href, dataQA }) {
  return (
    <S.Card
      href={`${Config.baseUrl}/${href}`}
      title={title}
      urlImage={urlImage}
      data-testid="Card_Category"
      data-qa={dataQA}
    >
      <img
        fetchpriority="high"
        src={urlImage}
        alt="Category card"
        title={title}
        className="cover-image"
      />
      <S.TitleCategoryCard data-testid="TitleCategoryCard">
        {title}
      </S.TitleCategoryCard>
    </S.Card>
  )
}
