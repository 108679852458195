import { FakableClass, FakableField, fakeClass } from 'fake-class'
import { Faker } from 'faker'

@FakableClass()
export class Store {
  /**
   * Store id
   *
   */
  @FakableField(() => Faker.number())
  id!: number | string

  /**
   * Store title
   *
   */
  @FakableField(() => Faker.string(10))
  title!: string

  /**
   * Store mobileCard
   *
   */
  @FakableField(() => Faker.url())
  mobileCard!: string

  /**
   * Store desktopCard
   *
   */
  @FakableField(() => Faker.string(10))
  desktopCard!: string
  /**
   * Store desktopCard
   *
   */
  @FakableField(() => Faker.string(10))
  imageUrl!: string

  /**
   * Store url
   *
   */
  @FakableField(() => Faker.url())
  url!: string

  /**
   * Store status
   *
   */
  @FakableField(() => Faker.number())
  status: number
  /**
   * Store type
   *
   */
  @FakableField(() => Faker.number())
  type: number

  /**
   * Store constructor
   *
   * @param params
   */

  constructor(params: Partial<Store>) {
    Object.assign(this, params)
  }
}

@FakableClass()
export class OfficialStore {
  /**
   * OfficialStore position
   *
   */
  @FakableField(() => Faker.string(10))
  position: string

  /**
   * OfficialStore stores
   *
   */
  stores = [fakeClass(Store)] as Store[]

  /**
   * OfficialStore constructor
   *
   * @param params
   */

  constructor(params: Partial<OfficialStore>) {
    Object.assign(this, params)
  }
}
