import { BaseFindOneRepository } from 'domain/BaseRepository'
import { HttpGetClient } from 'domain/HttpClient'
import { OfficialStore, Store } from '../Model/OfficialStore'

export type StoreDto = {
  IdOfficialStore: string
  Brand: string
  UrlLogo: string
  Status: number
  UrlOfficialStore: string
  VehicleType: number
}

export class RemoteOfficialStoreRepository
  implements BaseFindOneRepository<OfficialStore>
{
  constructor(private httpGetClient: HttpGetClient) {}

  /**
   * Get all available categories
   *
   * @returns
   */
  async findOne(): Promise<OfficialStore> {
    const result = await this.httpGetClient.get<StoreDto[]>(
      `/v1/loja-oficial/brands`
    )

    const stores = result.map(
      (store) =>
        new Store({
          id: store.IdOfficialStore,
          title: store.Brand,
          imageUrl: store.UrlLogo,
          url: store.UrlOfficialStore,
          status: store.Status,
          type: store.VehicleType
        })
    )

    return { position: 'top', stores }
  }
}
